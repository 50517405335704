'use client';

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ButtonCircle from 'components/ui/ButtonCircle';
import Text from 'components/ui/Text';
import { useTheme } from 'hooks/useTheme';
import Disclosure from './Disclosure';
import styles from './Accordion.module.scss';
const Child = ({
  label,
  iconType = 'chevronDown',
  children
}) => {
  const [globalTheme] = useTheme();
  return <Disclosure.Details data-sentry-element="unknown" data-sentry-component="Child" data-sentry-source-file="Child.jsx">
            {({
      isOpen
    }) => {
      return <>
                        <Disclosure.Summary className={cx(styles.toggle, {
          [styles.isOpen]: isOpen
        })}>
                            <Text.Theme className={cx(styles.label)} config={{
            'makers-reserve': {
              baseTheme: 'labelSmallAlt',
              themes: {
                large: 'labelMediumAlt'
              }
            },
            default: {
              baseTheme: 'labelMedium',
              themes: {
                large: 'labelLarge'
              }
            }
          }}>
                                {label}
                            </Text.Theme>

                            <div className={styles.icon}>
                                <ButtonCircle theme={globalTheme === 'makers-reserve' ? 'small-fill-makers-black' : 'small-fill-blue'} iconType={iconType} />
                            </div>
                        </Disclosure.Summary>
                        <Disclosure.Content className={styles.content}>
                            {children}
                        </Disclosure.Content>
                    </>;
    }}
        </Disclosure.Details>;
};
Child.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  iconType: PropTypes.string,
  label: PropTypes.string.isRequired
};
export default Child;