'use client';

import React, { Fragment, useEffect, useState } from 'react';
import { useMedia, useWindowScroll } from 'react-use';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import useMousePosition from 'hooks/useMousePosition';
import { useTheme } from 'hooks/useTheme';

import styles from './Ingredients.module.scss';

const ingredientTipTest = /(\{[a-zA-Z0-9\s]+\})/;

const Ingredients = ({ ingredients }) => {
    const [theme] = useTheme();

    const { y: scrollY } = useWindowScroll();

    const [bubble, setBubble] = useState({ show: false });

    const { x, y } = useMousePosition();

    useEffect(() => {
        setBubble({ ...bubble, show: false });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollY]);

    const handleActive = bubble => () => {
        setBubble({ ...bubble, show: true });
    };
    const handleInactive = () => setBubble({ ...bubble, show: false });

    const hasHover = useMedia('(hover: hover)');

    return ingredients ? (
        <div className={cx(styles.ingredients, styles[`theme--${theme}`])}>
            <div
                style={{ '--mouse-x': `${x}px`, '--mouse-y': `${y - 180}px` }}
                className={cx(styles.ingredientBubble, {
                    [styles['ingredientBubble--open']]: bubble.show,
                })}
            >
                <Text
                    baseTheme="labelSmall"
                    themes={{ large: 'labelLarge' }}
                    className={styles.bubbleTitle}
                >
                    {bubble?.title ?? ''}
                </Text>
                <Text baseTheme="bodySmall" themes={{ large: 'bodyMedium' }}>
                    {bubble?.description ?? ''}
                </Text>
            </div>

            <Text.Theme
                as="p"
                config={{
                    'makers-reserve': {
                        baseTheme: 'parkinsonMedium',
                        themes: {
                            large: 'parkinsonLarge',
                        },
                    },
                    default: {
                        baseTheme: 'headingLarge',
                    },
                }}
                className={styles.list}
            >
                {ingredients.map((ingredient, i) => {
                    if (
                        ingredientTipTest.test(ingredient?.ingredient) ||
                        ingredient?.bubble !== ''
                    ) {
                        const values = ingredient?.ingredient
                            ?.split(ingredientTipTest)
                            ?.filter(v => v !== '');

                        return (
                            <Fragment key={i}>
                                {values?.map((value, i) => {
                                    const last = i === values.length - 1;

                                    if (
                                        ingredientTipTest.test(value) ||
                                        value === ingredient.ingredient
                                    ) {
                                        const title = value
                                            .replace('{', '')
                                            .replace('}', '');

                                        const handler = handleActive({
                                            title,
                                            description: ingredient.bubble,
                                        });

                                        return (
                                            <Fragment key={i}>
                                                <span
                                                    className={styles.toolTip}
                                                    onClick={
                                                        !hasHover
                                                            ? handler
                                                            : null
                                                    }
                                                    onMouseOver={
                                                        hasHover
                                                            ? handler
                                                            : null
                                                    }
                                                    onMouseOut={handleInactive}
                                                >
                                                    {title}
                                                </span>
                                                {last ? null : ' '}
                                            </Fragment>
                                        );
                                    }
                                    return (
                                        <Fragment key={i}>
                                            <span>{value}</span>
                                            {last ? null : ' '}
                                        </Fragment>
                                    );
                                })}
                                {i === ingredients.length - 1 ? null : ', '}
                            </Fragment>
                        );
                    }
                    return (
                        <Fragment key={i}>
                            <span>{ingredient.ingredient}</span>
                            {i === ingredients.length - 1 ? null : ', '}
                        </Fragment>
                    );
                })}
            </Text.Theme>
        </div>
    ) : null;
};

Ingredients.propTypes = { ingredients: PropTypes.array };

export default Ingredients;
